import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import HitchButton from "components/HitchButton";
import { useNavigate } from "react-router-dom";
import { useHubConnections } from "context/HubConnectionsProvider";
import HitchDisplayBadgeList from "components/HitchDisplayBadgeList";
import HitchBadgeList from "components/HitchBadgeList";
import VuiInput from "components/VuiInput";
import HitchFormField from "components/HitchFormField";
import { Form, Formik } from "formik";

function SosVulnerabilities() {
    const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();
    const [pageNum, setPageNum] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');


    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("fad5c575-0f9a-4104-bf41-0d2eb57108b1", setVs);
            LoadThePage();
            return () => {
                dashboardConnection.off("fad5c575-0f9a-4104-bf41-0d2eb57108b1", setVs);
            }
        }
    }, [dashboardConnection, isConnectionReady]);

    function LoadThePage() {
        hitchDashCommand('sos', 'vulnerabilities', 'list', {
            pageIndex: pageNum,
            perPage: perPage,
            searchTerm: searchTerm
        });
    }

    useEffect(() => {
        LoadThePage();
    }, [pageNum, perPage, searchTerm]);

    function setVs(data) {
        console.log("fad5c575-0f9a-4104-bf41-0d2eb57108b1", data);
        if (data && data.items) {
            setList(data.items);
            setTotalResults(data.records);
        }

    }

    function viewDetails(row) {
        console.log('view Details', row.original.id);
        navigate(`./${row.original.id}`);
    }

    const cols = [
        { accessor: 'name', Header: 'Vulnerability', width: '25%' },
        { accessor: 'hitchStatus', Header: 'Status', width: '10%' },
        { accessor: 'severity', Header: 'Severity', width: '10%' },
        { accessor: 'ghsA_Id', Header: 'GitHub Advisory', width: '15%' },
        {
            accessor: 'cvE_Id',
            Header: 'CVE',
            width: '10%',
            Cell: ({ value }) => value || '-' // Or empty string '' if you want nothing
        },
        {
            accessor: 'ecosystems',
            Header: 'Ecosystems',
            width: '20%',
            Cell: ({ row }) => {
                const ecosystems = Array.from(new Set(row.original.packages?.map((p) => p.ecosystem) || []));

                // Inline flex container for better alignment
                return (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                        {ecosystems.map((eco) => (
                            <HitchBadgeList key={eco} value={eco} />
                        ))}
                    </div>
                );
            }
        },
        {
            accessor: 'actions',
            Header: 'Actions',
            width: '10%',
            Cell: ({ row }) => (
                <HitchButton variant="outlined" color="info" size="small" onClick={() => viewDetails(row)}>
                    View Details
                </HitchButton>
            )
        }
    ];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Vulnerability Management {searchTerm && `- "${searchTerm}"`}
                            </HitchTypography>
                        </HitchBox>
                        <HitchBox p={3} pl={0}>
                            <Formik
                                initialValues={{
                                    searchTerm: searchTerm || "",
                                }}
                                enableReinitialize
                                onSubmit={(values) => {
                                    console.log("values", values);
                                    setSearchTerm(values.searchTerm);
                                    setPageNum(1);
                                }} // Ensure saveEvidence is defined
                            >
                                {({ handleSubmit, setFieldValue, values }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <HitchBox display="flex" justifyContent="space-between" mb={3}>
                                            <HitchBox flexGrow={1}>
                                                <HitchFormField
                                                    name="searchTerm"
                                                    label="Search Vulnerabilities"
                                                    fullWidth
                                                    editMode={true}
                                                />
                                            </HitchBox>
                                            <HitchBox ml={2}>
                                                <HitchButton type="submit" variant="outlined" color="white">
                                                    Search
                                                </HitchButton>
                                            </HitchBox>
                                        </HitchBox>
                                    </Form>
                                )}
                            </Formik>

                            <HitchDataTableExternalPagination
                                table={{ columns: cols, rows: list }}
                                canSearch={false}
                                totalResults={totalResults}
                                onPageChange={(newPage, newPerPage) => { setPageNum(newPage); setPerPage(newPerPage); }}
                                paginated={true}
                                page={pageNum}
                                perPage={perPage}
                            />
                        </HitchBox>
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default SosVulnerabilities;
