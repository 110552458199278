import HitchPage from "components/HitchPage";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { Card, Divider, Grid, Modal } from "@mui/material";
import StatsCard from "widgets/Cards/StatsCard";
import { useHubConnections } from "context/HubConnectionsProvider";
import SosTaskList from "./SosTaskList";
import ChatControl from "hitch/chat/general/ChatControl";
import HitchButton from "components/HitchButton";
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import HitchFrame from "hitch/hitchframe";
import HitchTitle from "components/HitchTitle";
import UploadEvidenceModal from "./UploadEvidenceModal";
import { ContactPageOutlined } from "@mui/icons-material";
import { HiMagnifyingGlass } from "react-icons/hi2";
import HitchSelectDialog from "components/HitchSelectDialog";

function SosDashboard() {
    const hardCodedTaskId = '2dd40f66-0f8d-4d8c-b21a-96e35d04b4f1';
    const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();
    const [topLevelTask, setTopLevelTask] = useState(hardCodedTaskId);
    const [score, setScore] = useState(0);
    const [maxScore, setMaxScore] = useState(0);
    const [tasks, setTasks] = useState([]);
    const [chatMessages, setChatMessages] = useState([]);
    const [slackBotMenu, setSlackBotMenu] = useState(null);
    const [searchModal, setSearchModal] = useState(false);
    const closeSlackBotMenu = () => setSlackBotMenu(null);
    const [theTask, setTheTask] = useState(null);
    const [collapse, setCollapse] = useState(false);
    const { sessionId } = useParams();
    const navigate = useNavigate(); // Initialize the navigate function
    const [selectedFrameId, setSelectedFrameId] = useState(null);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

    const [frameOpen, setFrameOpen] = useState(false);

    const closeFrame = () => setFrameOpen(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        if (!sessionId) {
            newChat();
        }
    }, [sessionId, navigate]);

    function newChat() {
        const newSessionId = uuidv4();
        navigate(`/third/sos/dashboard/${newSessionId}`); // Redirect with the new sessionId
        console.log('frame closed, clearing frame Id');
        setSelectedFrameId(null);
    }

    function loadFrame(frameId) {
        setSelectedFrameId(frameId);
        setFrameOpen(true);
    }

    useEffect(() => {
        if (!frameOpen) {
            console.log('frame closed, clearing frame Id');
            setSelectedFrameId(null);
        }
    }, [frameOpen]);

    useEffect(() => {
        if(theTask) {
            console.log('theTask', theTask);
        }
    }, [theTask]);


    const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("20e1742e-4b87-4bab-9b36-7bd8bea1e7b2", setTheTask);
            dashboardConnection.on("02c97151-7d0f-487e-8c7e-5f6c206001e0", incomingTasks);
            LoadThePage();
            return () => {
                dashboardConnection.off("20e1742e-4b87-4bab-9b36-7bd8bea1e7b2", setTheTask);
                dashboardConnection.off("02c97151-7d0f-487e-8c7e-5f6c206001e0", incomingTasks);
            }
        }
    }, [dashboardConnection, isConnectionReady, topLevelTask]);

    function incomingTasks(data) {
        console.log('incomingTasks', data);
        setList(data);
    }


    function LoadThePage() {
        hitchDashCommand('sos', 'task', 'get', {
            taskId: topLevelTask
        });
        hitchDashCommand('sos', 'task', 'list', {
            page: 1,
            pageSize: 20
        });
    }

    useEffect(() => {
        if (theTask) {
            setScore(theTask.score);
            setMaxScore(theTask.maxScore);
        }
    }, [theTask]);

    useEffect(() => {
        fetchTasks();
        fetchChatMessages();
    }, []);

    const fetchTasks = () => {
        setTasks([
            { id: 1, name: "Task 1", status: "Pending" },
            { id: 2, name: "Task 2", status: "Completed" },
        ]);
    };

    const fetchChatMessages = () => {
        setChatMessages([
            { user: "AI", message: "Your current score is 85. To improve, consider reviewing Task 1." },
        ]);
    };

    function uploadFile() {
        setIsUploadModalOpen(true);
    }

    function changeCertification() {
        setSearchModal(true);
    }

    const renderMenu = (state, close) => (
        <Menu
            anchorEl={state}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(state)}
            onClose={close}
            keepMounted
        >
            <MenuItem onClick={close}>Action</MenuItem>
            <MenuItem onClick={close}>Another action</MenuItem>
            <MenuItem onClick={close}>Something else here</MenuItem>
        </Menu>
    );

    function dialogChange(e) {
        console.log('dialogChange', e);
        setTopLevelTask(e);
    }



    return (
        <HitchPage 
            title={"Certification Dashboard"} 
            subTitle={theTask?.name}
            icon={<HiMagnifyingGlass />}
            tooltip={"Change Certification Standard"}
            action={() => changeCertification()}
            >
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <StatsCard
                                title={`${theTask?.name} Score`}
                                count={score}
                                max={maxScore}
                                percentage={{
                                    color: "primary",
                                    label: "+5 since last week",
                                }}
                                action={{
                                    type: "internal",
                                    route: `/third/sos/tasks/${topLevelTask}`,
                                    label: "view more",
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <Card variant="outlined">
                                <HitchTitle
                                    title="Submit Evidence"
                                    subTitle="Upload Evidence for your audit." />

                                <HitchButton
                                    color="white"
                                    variant="outlined"
                                    onClick={() => uploadFile()}>
                                    Upload
                                </HitchButton>
                            </Card>
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <SosTaskList
                                tasks={theTask?.children}
                                title={theTask?.name}
                                loadFrame={loadFrame}
                            />
                        </Grid>

                        <Divider aria-hidden="true"/>

                        <Grid item xs={12}>
                            <HitchBox p={3}>
                                <HitchTitle
                                    title="Chat With AI"
                                    subTitle={`Session ${sessionId}`} />

                                <ChatControl
                                    maxHeight={collapse ? null : "400px"}
                                    chatId={sessionId}
                                    configurationId={'e4e88e6d-1eb2-4ac0-bea8-e405d324fc63'} />

                                <HitchButton color="white" variant="outlined" onClick={() => setCollapse(!collapse)}>
                                    {collapse ? 'Collapse' : 'Expand'}
                                </HitchButton>

                                <HitchButton color="white" variant="outlined" onClick={() => newChat()}>
                                    Clear !
                                </HitchButton>
                            </HitchBox>
                        </Grid>
                    </Grid>
                </HitchBox>
            </HitchBox>

            <HitchFrame
                open={frameOpen}
                onClose={closeFrame}
            />

            <UploadEvidenceModal
                headTaskId={topLevelTask}
                open={isUploadModalOpen}
                onClose={() => setIsUploadModalOpen(false)}
                onSave={(uploadedFile, relatedTasks) => console.log(`on save: ${uploadFile}`, relatedTasks)}
            />

            <HitchSelectDialog
                open={searchModal}
                handleClose={() => setSearchModal(false)}
                title="Choose Framework"
                label="Framework"
                onChange={(e) => dialogChange(e)}
                selectedValue={topLevelTask}
                theOptions={list}
            />
        </HitchPage>
    );
}

export default SosDashboard;