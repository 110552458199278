import { Chip, Divider, Link } from "@mui/material";
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import HitchPage from "components/HitchPage";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import MarkdownRenderer from "hitch/chat/MarkdownRenderer";
import { useEffect, useState } from "react";
import { AiFillBackward } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";

function SosVulnerabilityDetails() {
    const [data, setData] = useState({});
    const { vulnerabilityId } = useParams();
    const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();
    const navigate = useNavigate();

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("7a856ce7-0f2c-4e1d-ad47-e79e7bd35ce4", setVs);
            loadThePage();
            return () => {
                dashboardConnection.off("7a856ce7-0f2c-4e1d-ad47-e79e7bd35ce4", setVs);
            }
        }
    }, [dashboardConnection, isConnectionReady]);

    function loadThePage() {
        hitchDashCommand('sos', 'vulnerabilities', 'get', {
            vulnerabilityId: vulnerabilityId
        });
    }

    function setVs(data) {
        if (data && data.id == vulnerabilityId) {
            console.log('setVs', data);
            setData(data);
        } else {
            console.log('update rejected.  Mismatch on vulnerability id.');
        }
    }

    return (
        <HitchPage
            title={data?.summary}
            subTitle={`Hitch: ${vulnerabilityId} GH: ${data?.ghsA_Id} CVE: ${data?.cvE_Id}`}
            icon={<AiFillBackward />}
            tooltip="Back to Vulnerabilities"
            action={() => navigate('/third/sos/vulnerabilities')}>

            <HitchBox sx={{ p: 4, bgcolor: 'white', borderRadius: 2, boxShadow: 1, maxWidth: 900, mx: 'auto' }}>
                <HitchTypography variant="h4" gutterBottom>{name}</HitchTypography>
                <Chip 
                    label={`Severity: ${data?.severity}`} 
                    color={data?.severity === 'high' ? 'error' : 'warning'} />
                <Chip 
                    label={`CVSS v3 Score: ${data?.cvsSv3Score}`} 
                    sx={{ ml: 1 }} 
                    color={"info"}
                />
                <Chip 
                    label={`CVSS v4 Score: ${data?.cvsSv4Score}`} 
                    sx={{ ml: 1 }} 
                    color={"info"}
                />

                <HitchTypography variant="h6" sx={{ mt: 3 }}>Summary</HitchTypography>
                <HitchTypography variant="body1">{data?.summary}</HitchTypography>

                <HitchTypography variant="h6" sx={{ mt: 3 }}>Description</HitchTypography>
                <MarkdownRenderer content={data?.description} />
                {/* <HitchTypography variant="body2" component="div" sx={{ whiteSpace: 'pre-line' }}>
                    {data?.description}
                </HitchTypography> */}

                <Divider sx={{ my: 3 }} />

                <HitchTypography variant="h6">Identifiers</HitchTypography>
                {data?.identifiers?.map((id) => (
                    <HitchTypography key={id.id} variant="body2">
                        {id.identifierType}: {id.identifierValue}
                    </HitchTypography>
                ))}

                <HitchTypography variant="h6" sx={{ mt: 3 }}>References</HitchTypography>
                {data?.references?.map((ref) => (
                    <HitchTypography key={ref.id} variant="body2">
                        <Link href={ref.referenceURL} target="_blank" rel="noopener noreferrer">
                            {ref.referenceURL}
                        </Link>
                    </HitchTypography>
                ))}

                <HitchTypography variant="h6" sx={{ mt: 3 }}>Affected Packages</HitchTypography>
                {data?.packages?.map((pkg) => (
                    <HitchBox key={pkg.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                        <HitchTypography variant="body2">
                            {pkg.packageName} ({pkg.ecosystem}) – {pkg.vulnerableVersionRange} → {pkg.firstPatchedVersion}
                        </HitchTypography>
                        <HitchButton variant="outlined" color="white" size="small" sx={{ ml: 2 }}>
                            Check Repositories
                        </HitchButton>
                    </HitchBox>
                ))}

                <HitchTypography variant="h6" sx={{ mt: 3 }}>CWE</HitchTypography>
                {data?.cwEs?.map((cwe) => (
                    <HitchTypography key={cwe.id} variant="body2">
                        {cwe.cwE_Id}: {cwe.cwE_Name}
                    </HitchTypography>
                ))}

                <Divider sx={{ my: 3 }} />

                <HitchTypography variant="caption" color="text.secondary">
                    Published: {new Date(data?.vulnerability?.publishedAt).toLocaleString()}
                </HitchTypography>
            </HitchBox>

        </HitchPage>
    );
}

export default SosVulnerabilityDetails;